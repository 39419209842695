import { Typography, TypographyProps } from "@mui/material";
import { forwardRef } from "react";

type HomeTypographyProps = {
  variant?:
    | "bigTitle"
    | "title1"
    | "title2"
    | "semiTitle"
    | "overtitle"
    | "body1"
    | "span";
} & Omit<TypographyProps, "variant" | "color">;

const HomeTypography = forwardRef<any, HomeTypographyProps>(
  ({ variant, sx, ...props }, ref) => {
    return (
      <Typography
        ref={ref}
        component={
          variant === "span"
            ? "span"
            : variant === "bigTitle"
            ? "h1"
            : variant === "title1"
            ? "h3"
            : variant === "title2"
            ? "h2"
            : variant === "semiTitle"
            ? "h6"
            : variant === "overtitle"
            ? "h6"
            : "p"
        }
        sx={{
          color: "#000",
          ...(variant === "bigTitle"
            ? {
                fontSize: { xl: "150px", lg: "120px", md: "96px", xs: "40px" },
                lineHeight: {
                  xl: "154px",
                  lg: "124px",
                  md: "100px",
                  xs: "44px",
                },
                fontWeight: 700,
                // titolone iniziale
              }
            : variant === "title1"
            ? {
                fontSize: { lg: "64px", md: "52px", xs: "20px" },
                lineHeight: { lg: "68px", md: "56px", xs: "28px" },
                fontWeight: 700,
                // titoli dei case studies
              }
            : variant === "title2"
            ? {
                fontSize: { lg: "96px", md: "64px", xs: "48px" },
                lineHeight: { lg: "130px", md: "94px", xs: "60px" },
                fontWeight: 700,
                // ux driven software house + ecco cosa facciamo + credono in noi
              }
            : variant === "semiTitle"
            ? {
                fontSize: { md: "36px", xs: "16px" },
                lineHeight: { md: "40px", xs: "20px" },
                fontWeight: 700,
                // lista sezioni normale
              }
            : variant === "overtitle"
            ? {
                fontSize: { md: "20px", xs: "14px" },
                lineHeight: { md: "28px", xs: "18px" },
                fontWeight: 400,
                textTransform: "uppercase",
              }
            : // lista sezioni mobile
            variant === "body1"
            ? {
                fontSize: { md: "24px", xs: "16px" },
                lineHeight: { md: "32px", xs: "20px" },
                fontWeight: 400,
                color: "rgba(0, 0, 0, .6)",
              }
            : variant === "span"
            ? {
                fontSize: "inherit",
                lineHeight: "inherit",
                fontWeight: "inherit",
              }
            : {}),
          ...sx,
        }}
        {...props}
      />
    );
  }
);
export default HomeTypography;
