import { Box } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { l_home } from "../../locales";
import HomeTypography from "../../theme/HomeTypography";

const BelieveInUs = () => {
  return (
    <Box
      sx={{
        width: "90%",
        maxWidth: "1800px",
        margin: "auto",
        // marginTop: "3500px",
        marginTop: { md: "250px", xs: "90px" },
        marginBottom: { md: "400px", xs: "220px" },
      }}
    >
      <HomeTypography
        variant="title2"
        sx={{ marginBottom: { md: "97px", xs: "32px" } }}
      >
        {l_home("believeInUs.title")}
      </HomeTypography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignContent: "space-between",
          gap: { lg: "96px", md: "64px", xs: "32px" },
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <Box sx={{ flexShrink: 0, width: { md: "250px", xs: "120px" } }}>
          <StaticImage
            alt="Intesa Sanpaolo Assicura"
            src="../../images/isatra-logo.png"
            width={255}
          />
        </Box>
        <Box
          sx={{
            flexShrink: 0,
            width: { md: "285px", xs: "165px" },
          }}
        >
          <StaticImage
            alt="Certiblok"
            src="../../images/certiblok-logo.png"
            width={290}
          />
        </Box>
        <Box
          sx={{
            flexShrink: 0,
            width: { md: "88px", xs: "40px" },
          }}
        >
          <StaticImage
            alt="District"
            src="../../images/district-logo.png"
            width={90}
          />
        </Box>
        <Box
          sx={{
            flexShrink: 0,
            width: { md: "250px", xs: "170px" },
          }}
        >
          <StaticImage
            alt="Bonus X"
            src="../../images/bonus-x-logo.png"
            width={250}
          />
        </Box>
        <Box
          sx={{
            flexShrink: 0,
            width: { md: "180px", xs: "104px" },
          }}
        >
          <StaticImage
            alt="Nowr"
            src="../../images/nowr-2-logo.png"
            width={180}
          />
        </Box>

        <Box
          sx={{
            flexShrink: 0,
            width: { md: "150px", xs: "90px" },
          }}
        >
          <StaticImage
            alt="Startup geeks"
            src="../../images/sgeeks-logo.png"
            width={150}
          />
        </Box>
        <Box
          sx={{
            flexShrink: 0,
            width: { md: "190px", xs: "134px" },
          }}
        >
          <StaticImage
            alt="Tecnocasa"
            src="../../images/tecnocasa-logo.png"
            width={190}
          />
        </Box>
        <Box
          sx={{
            flexShrink: 0,
            width: { md: "215px", xs: "154px" },
          }}
        >
          <StaticImage
            alt="Sit"
            src="../../images/angel-eye-logo.png"
            width={215}
          />
        </Box>
        <Box
          sx={{
            flexShrink: 0,
            width: { md: "155px", xs: "85px" },
          }}
        >
          <StaticImage alt="Sit" src="../../images/sit-logo.png" width={155} />
        </Box>
        <Box
          sx={{
            flexShrink: 0,
            width: { md: "88px", xs: "64px" },
          }}
        >
          <StaticImage
            alt="Infinite play"
            src="../../images/infinite-play-logo.png"
            width={88}
          />
        </Box>
        <Box
          sx={{
            flexShrink: 0,
            width: { md: "230px", xs: "105px" },
          }}
        >
          <StaticImage
            alt="Sit"
            src="../../images/bel-invest-logo.png"
            width={230}
          />
        </Box>
        <Box
          sx={{
            flexShrink: 0,
            width: { md: "150px", xs: "80px" },
          }}
        >
          <StaticImage
            alt="LeVillage"
            src="../../images/le-village-logo.png"
            width={150}
          />
        </Box>
        <Box
          sx={{
            flexShrink: 0,
            width: { md: "190px", xs: "100px" },
          }}
        >
          <StaticImage
            alt="Joule"
            src="../../images/joule-logo.png"
            width={190}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default BelieveInUs;
