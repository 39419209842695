import { Box } from "@mui/material";
import SEO from "../../components/old/seo";
import Footer from "../../components/shared/Footer/Footer";
import BelieveInUs from "./components/BelieveInUs/BelieveInUs";
import CaseStudies from "./components/CaseStudies/CaseStudies";
import Title from "./components/Title/Title";
import UXDrivenSoftwareHouse from "./components/UXDrivenSoftwareHouse/UXDrivenSoftwareHouse";
import WhatWeDo from "./components/WhatWeDo/WhatWeDo";
import { l_home } from "./locales";

const Home = () => {
  return (
    <>
      <SEO
        title={l_home("seo.title")}
        description={l_home("seo.description")}
      />
      <Title />
      <CaseStudies />
      <UXDrivenSoftwareHouse />
      <WhatWeDo />
      <BelieveInUs />
      <Footer />
    </>
  );
};

export default Home;
