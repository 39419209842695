import { Box, Fade, Stack, useMediaQuery, useTheme } from "@mui/material";
import gsap, { Power3 } from "gsap";
import { ReactNode, useEffect, useRef } from "react";
import { SwitchTransition } from "react-transition-group";
import useResizeObserver from "use-resize-observer";
import { l_home } from "../../locales";
import HomeTypography from "../../theme/HomeTypography";

type WhatWeDoListSmallProps = {
  listItems: {
    title: string;
    description: string;
    image: ReactNode;
    imgSrc?: string;
  }[];
};
type WhatWeDoListProps = {
  listItems: {
    title: string;
    description: string;
    image?: ReactNode;
    imgSrc?: string;
  }[];
  activeListItem: number;
};

export const WhatWeDoListSmall: React.FC<WhatWeDoListSmallProps> = ({
  listItems,
}) => {
  return (
    <>
      <Box
        sx={{
          display: { md: "none", xs: "block" },
        }}
      >
        <HomeTypography
          variant="title2"
          sx={{ marginBottom: { md: "32px", xs: "16px" } }}
        >
          {l_home("whatWeDo.title")}
        </HomeTypography>
        <HomeTypography
          variant="body1"
          sx={{
            fontSize: { md: "20px" },
            lineHeight: { md: "28px" },
            color: "rgba(0, 0, 0, 0.6)",
            boxSizing: "border-box",
            width: { md: "50%", xs: "100%" },
            paddingRight: { md: "32px", xs: 0 },
            maxWidth: "700px",
          }}
        >
          {l_home("whatWeDo.subtitle")}
        </HomeTypography>
      </Box>
      <Box
        sx={{
          display: { md: "none", xs: "block" },
          marginTop: "72px",
          // marginBottom: "120px",
          top: 0,
          left: 0,
        }}
        className="whatwedo-list"
      >
        {listItems.map((listItem, i) => {
          return (
            <Box
              key={listItem.title + i}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                marginBottom: "80px",
              }}
            >
              <Box
                sx={{
                  width: "80%",
                  height: "100%",

                  alignSelf: i % 2 === 0 ? "flex-start" : "flex-end",
                  marginBottom: "40px",
                }}
              >
                {listItem.image}
              </Box>
              <Box sx={{ width: { md: "100%", xs: "80%" } }}>
                <HomeTypography
                  variant="overtitle"
                  sx={{ marginBottom: "16px", cursor: "pointer" }}
                >
                  {listItem.title}
                </HomeTypography>
                <HomeTypography variant="semiTitle">
                  {listItem.description}
                </HomeTypography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export const WhatWeDoList: React.FC<WhatWeDoListProps> = ({
  listItems,
  activeListItem,
}) => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  const listItem = listItems[activeListItem];

  const imageRef = useRef<HTMLDivElement>(null);
  const imageContainerRef = useRef<HTMLDivElement>(null);
  useResizeObserver<HTMLDivElement>({
    box: "border-box",
    ref: imageContainerRef,
    onResize: (size) => {
      const imageContainer = imageRef.current;
      if (imageContainer) {
        imageContainer.style.aspectRatio = "unset";
        imageContainer.style.width = `${Math.min(size.height ?? 0, 500)}px`;
      }
    },
  });

  const longestDescription = listItems.reduce((prev, item) => {
    return prev.description.length >= item.description.length ? prev : item;
  }, listItems[0]);
  const longestListItemRef = useRef<HTMLDivElement>(null);

  const { height: longestListItemHeight = 1 } = useResizeObserver({
    ref: longestListItemRef,
  });

  useEffect(() => {
    const minClientWidth = 500;

    const tl = gsap.timeline();
    tl.fromTo(
      ".whatwedo-image-" + activeListItem,
      {
        left: activeListItem % 2 !== 0 ? "unset" : 0,
        right: activeListItem % 2 !== 0 ? 0 : "unset",
        width: `${Math.min(
          imageContainerRef.current?.clientHeight || minClientWidth,
          minClientWidth
        )}px`,
      },
      {
        width: "100%",
        duration: 0.5,
        ease: Power3.easeIn,
      }
    )
      .to(".whatwedo-image-" + activeListItem, {
        left: activeListItem % 2 === 0 ? "unset" : 0,
        right: activeListItem % 2 === 0 ? 0 : "unset",
        duration: 0,
      })
      .to(".whatwedo-image-" + activeListItem, {
        width: `${Math.min(
          imageContainerRef.current?.clientHeight || minClientWidth,
          minClientWidth
        )}px`,
        ease: Power3.easeOut,
        duration: 0.5,
      });

    return () => {
      tl.kill();
    };
  }, [activeListItem, isOverMd]);

  return (
    <>
      <Box
        sx={{
          display: { xs: "none", md: "block" },
          height: "100%",
        }}
      >
        <Stack
          sx={{
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            height: "100%",
          }}
        >
          <Box
            sx={{
              // backgroundColor: "paleturquoise",
              flex: 1,
              width: "100%",
              position: "relative",
              marginBottom: "32px",
            }}
            ref={imageContainerRef}
          >
            {listItems.map((item, index) => (
              <Box
                key={index}
                ref={imageRef}
                className={"whatwedo-image-" + index}
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  height: "100%",
                  transition: "opacity 500ms",
                  opacity: index === activeListItem ? 1 : 0,
                  transitionDelay:
                    index === activeListItem ? "100ms" : undefined,
                  backgroundImage: `url(${item.imgSrc})`,
                  backgroundSize: "auto 150%",
                  // backgroundSize: "100% 100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "50% 50%",
                  maxHeight: "500px",
                  aspectRatio: "1/1",
                  borderRadius: "24px",
                }}
              />
            ))}
          </Box>
          <Box
            sx={{
              width: "100%",
              height: longestListItemHeight,
              flexShrink: 0,
              // backgroundColor: "lightblue"
            }}
          >
            <SwitchTransition>
              <Fade
                key={listItem.title}
                appear
                easing={theme.transitions.easing.easeIn}
                timeout={{ enter: 300, exit: 300 }}
              >
                <Box>
                  <HomeTypography
                    variant="overtitle"
                    sx={{ marginBottom: "16px" }}
                  >
                    {listItem.title}
                  </HomeTypography>
                  <HomeTypography
                    variant="semiTitle"
                    sx={{
                      fontSize: "min(36px, 5vh)",
                      lineHeight: "min(40px, 6vh)",
                      // lista sezioni normale
                    }}
                  >
                    {listItem.description}
                  </HomeTypography>
                </Box>
              </Fade>
            </SwitchTransition>
          </Box>
        </Stack>
      </Box>
      <Box
        ref={longestListItemRef}
        sx={{ visibility: "hidden", zIndex: -1, position: "absolute" }}
        aria-hidden
      >
        <HomeTypography variant="overtitle" sx={{ marginBottom: "16px" }}>
          {longestDescription.title}
        </HomeTypography>
        <HomeTypography
          variant="semiTitle"
          sx={{
            fontSize: "min(36px, 5vh)",
            lineHeight: "min(40px, 6vh)",
            // lista sezioni normale
          }}
        >
          {longestDescription.description}
        </HomeTypography>
      </Box>
    </>
  );
};
