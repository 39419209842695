import HomeScreen from "../features/homepage";
import { graphql } from "gatsby";

export default HomeScreen;

export const query = graphql`
  query HomeMainQuery($localeRegex: String) {
    allContentfulArticle(
      limit: 3
      filter: { node_locale: { regex: $localeRegex }, isVisible: { eq: true } }
      sort: { order: DESC, fields: publishedAt }
    ) {
      edges {
        node {
          id
          slug
          title
          isVisible
          updatedAt
          publishedAt
          category {
            title
            fullname
          }
          foregroundImage {
            fluid(maxWidth: 1000, quality: 95) {
              ...GatsbyContentfulFluid_withWebp
              ...GatsbyContentfulFluid
            }
          }
          author {
            name
            surname
            role
          }
        }
      }
    }
  }
`;
