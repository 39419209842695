import { DeepKeyof, language } from "../../traductions";

const traduction = {
  it: {
    seo: {
      title: "Mabiloft | UX Driven Software House",
      description:
        "Crediamo che il successo sia il risultato di un lavoro di squadra, di una visione ambiziosa e di una costante ricerca dell'eccellenza. Da Padova progettiamo e sviluppiamo i prodotti digitali del futuro, costruendo app mobile, web app, UX e UI design.",
    },
    title: {
      title1: "Progettiamo ",
      title2: "e sviluppiamo ",
      title3: "i prodotti digitali",
      title4: "del futuro.",
      subtitlePartGeneric: "E ci piace ",
      subtitle1: "giocare a biliardino.",
      subtitle2: "mangiare la pizza.",
      subtitle3: "raccontare freddure.",
      subtitle4: "Flutter.",
      subtitle5: "andare al sushi insieme.",
      subtitle6: "dormire sui pouf.",
      subtitle7: "Figma.",
      subtitle8: "bere energy drink.",
      subtitle9: "condividere meme.",
      subtitle10: "ReactJS.",
      subtitle11: "fare workshop.",
      subtitle12: "giocare a pingpong.",
    },
    caseStudies: {
      description1:
        "Sviluppiamo strumenti per semplificare la burocrazia italiana",
      description2:
        "Accompagniamo l’accelerazione di centinaia di startup da una piattaforma online",
      description3: "Creiamo un mondo di esperienze coinvolgenti",
      description4:
        "Digitalizziamo i flussi assicurativi di una delle più grandi banche italiane",
      buttonText: "Vai al case study",
    },
    UxDriven: {
      text0: "Ci definiamo ",
      text1:
        "una UX-Driven software house perché le nostre scelte vengono guidate dalla User Experience, nel ",
      textDesign: "design",
      text2: " come nel ",
      textCode: "<codice>",
      text3: ";",
    },
    whatWeDo: {
      title: "Ecco cosa facciamo",
      subtitle:
        "Sviluppiamo e progettiamo prodotti digitali non dando mai nulla per scontato. Siamo specializzati nello sviluppo di app mobile e piattaforme: lavoriamo seguendo un flusso ben definito, che ci permette di trovare sempre le migliori soluzioni per i nostri clienti.",
      listItem1Title: "Sviluppo app e web",
      listItem1Description:
        "Sviluppiamo i nostri prodotti con la massima cura per il codice e per la user experience.",
      listItem2Title: "UX/UI Design",
      listItem2Description:
        "Il design è l’anima del nostro lavoro, progettiamo ponendo gli utenti al centro e senza perdere di vista gli obiettivi di business.",
      listItem3Title: "Workshop",
      listItem3Description:
        "Aiutiamo i nostri clienti a visualizzare le loro esigenze e a inserirle in modo coerente nel progetto applicando i principi del design thinking.",
      listItem4Title: "Supporto dev",
      listItem4Description:
        "Siamo innamorati delle tecnologie che utilizziamo e vogliamo trasmettere la passione delle nostre scelte, ecco perché offriamo consulenze in ambito tecnologico.",
    },
    believeInUs: {
      title: "Credono in noi",
    },
  },
  en: {
    seo: {
      title: "Mabiloft | UX Driven Software House",
      description:
        "We believe that success is the result of teamwork, ambitious vision and a constant pursuit of excellence. From Padua we design and develop the digital products of the future, building mobile apps, web apps, UX and UI design.",
    },
    title: {
      title1: "We design",
      title2: "and develop",
      title3: "digital products",
      title4: "of the future.",
      subtitlePartGeneric: "And we like ",
      subtitle1: "table football.",
      subtitle2: "eating pizza.",
      subtitle3: "cracking jokes.",
      subtitle4: "Flutter.",
      subtitle5: "to eat sushi.",
      subtitle6: "to sleep on poufs.",
      subtitle7: "Figma.",
      subtitle8: "energy drinks.",
      subtitle9: "sharing memes.",
      subtitle10: "ReactJS.",
      subtitle11: "doing workshops.",
      subtitle12: "table tennis.",
    },
    caseStudies: {
      description1: "We develop tools to simplify Italian bureaucracy",
      description2:
        "We assist the acceleration of hundreds of startups through a online platform",
      description3: "We create a world of engaging experiences",
      description4:
        "We digitize the insurance flow of one of the biggest Italian banks",
      buttonText: "Go to the case study",
    },
    UxDriven: {
      text0: "We define ourselves ",
      text1:
        "as a UX-Driven software house because our choices are guided by User Experience, both in ",
      textDesign: "design",
      text2: " and in ",
      textCode: "<code>",
      text3: ";",
    },
    whatWeDo: {
      title: "That's what we do",
      subtitle:
        "We never take anything for granted when we design and develop digital products. We are specialized in developing mobile apps and platforms: we work following a well-defined flow that allows to always find the best solutions for our clients.",
      listItem1Title: "App and web development",
      listItem1Description:
        "We develop our products with utmost care for code and user experience.",
      listItem2Title: "UX/UI Design",
      listItem2Description:
        "Design is the soul of our job, we put the focus on the users and without losing sight of the business goals.",
      listItem3Title: "Workshop",
      listItem3Description:
        "We help our clients focus on their needs and coherently place them into the project by applying principles of design thinking.",
      listItem4Title: "Dev support",
      listItem4Description:
        "We love the technologies that we use and want to share our passion, and that's why we offer counseling in the technological field.",
    },
    believeInUs: {
      title: "Believe in us",
    },
  },
};
export const l_home = (
  trad: DeepKeyof<typeof traduction[keyof typeof traduction]>
) => {
  const tokens = trad.split(".");
  let resource = traduction && (traduction as any)[language.code];
  if (!resource) {
    return tokens[tokens.length - 1];
  }

  for (let c = 0; c < tokens.length; c++) {
    resource = resource[tokens[c]];
    if (!resource) {
      // resource not found
      return trad;
    }
  }

  return resource;
};
